import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import Slider from 'Components/Slider';
import CardCalendar from 'Components/CardCalendar';
import s from './CalendarSlider.module.scss';

const CalendarSlider = ({title, items, url, name}) => {
    const {t} = useTranslation();

    if (items.length === 0) {
        return null;
    }

    const displayTitle = _.isEmpty(title) ? t('calendarSlider.title', {name: name}) : title;

    let button = null;
    if(!_.isEmpty(url)) {
        button = {
            url: url,
            title: t('calendarSlider.buttonTitle'),
        };
    }

    return (
        <div className={s['CalendarSlider']}>
            <div className={s['CalendarSlider__Wrap']}>
                <div className={s['CalendarSlider__Slider']}>
                    <Slider
                        items={items}
                        Card={CardCalendar}
                        title={displayTitle}
                        button={button}
                        emptyMsg={t('calendarSlider.empty')}
                    />
                </div>
            </div>
        </div>
    );
};

CalendarSlider.propTypes = {
    title: PropTypes.string,
    items: PropTypes.array.isRequired,
    url: PropTypes.string,
    name: PropTypes.string,
};

CalendarSlider.defaultProps = {
    title: '',
    items: [],
    url: '',
    name: '',
};

export default CalendarSlider;
