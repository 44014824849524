import React from 'react';
import PropTypes from 'prop-types';
import {useTranslation} from 'next-i18next';
import _ from 'lodash';
import {sanitizeText, sanitizeLineBreaks} from 'utils/html';
import DisplayDateTime from 'Components/DisplayDateTime';
import ArrowLarge from 'Assets/svg/arrowLarge.svg';
import s from './CardCalendar.module.scss';

const CardCalendar = ({title, url, place, startDate, endDate, excerpt}) => {
    const {t} = useTranslation();

    const sanitizedTitle = sanitizeText(title);
    const sanitizedExcerpt = sanitizeLineBreaks(excerpt);

    const displayPlace = _.isEmpty(place) ? 'HRF' : place;

    return(
        <article className={s['CardCalendar']}>
            <a className={s['CardCalendar__Link']} href={url}>
                <span
                    className={'sr-only'}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
            </a>
            <div className={s['CardCalendar__Content']}>
                <div className={s['CardCalendar__Meta']}>
                    {!_.isEmpty(displayPlace) &&
                        <div className={s['CardCalendar__Place']}>
                            <span className={'sr-only'}>{t('cardCalendar.place')}: </span>
                            {displayPlace}
                        </div>
                    }

                    <div className={s['CardCalendar__Date']}><DisplayDateTime startDate={startDate} endDate={endDate} /></div>
                </div>
                <h3
                    className={s['CardCalendar__Title']}
                    dangerouslySetInnerHTML={{__html: sanitizedTitle}}
                />
                <p
                    className={s['CardCalendar__Text']}
                    dangerouslySetInnerHTML={{__html: sanitizedExcerpt}}
                />

                <div className={s['CardCalendar__Button']}><ArrowLarge /></div>
            </div>
        </article>
    );
};

CardCalendar.propTypes = {
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    place: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    excerpt: PropTypes.string,
};

CardCalendar.defaultProps = {
    title: '',
    url: '',
    place: '',
    startDate: '',
    endDate: '',
    excerpt: '',
};

export default CardCalendar;
